.payment-footer-container {
  display: flex;
  margin: 0 auto;
  .payment-footer-ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 0px 5px 5px #ffffff2b;
  }
  li {
    margin: 2rem;
  }
  .payment-footer-img {
    width: 100px;
    height: 60px;
    border-radius: 0;
  }
}

@media only screen and (max-width: 667px) {
  .payment-footer-container {
    display: none;
  }
}
