.trade-page-sub-navbar {
    li {
        margin-top: 0;
    }
    .sub-navbar-links{
        display: flex;
        align-items: center;
        color: #a3c8e3;
        height: 2.5rem;
    }
}