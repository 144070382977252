// scss-lint:disable VendorPrefix, Indentation
$bg: #616161;
$text-light: #fafafa;
$text: #212121;
$placeholder: #999;
$panel-bg: #0a5f76;
$shadow-dark: rgba(0, 0, 0, 0.65);
$button: #648eb3;
$button-hover: #fff;
$button-active: #18aa7c;

//MIXINS
@mixin transition($time) {
  -webkit-transition: all $time;
  transition: all $time;
}

.forgotten-container {
  margin-top: 100px;
}

.forgotten-page-header {
  // font-family: "Press Start 2P", cursive;
  color: #648eb3 !important;
  font-size: 60px;
  left: 5%;
  margin-left: 40px;
  filter: blur(1px);
  font-weight: bolder;
  position: absolute;
}
a {
  @include transition(0.4s);
  color: $button-active;
  text-decoration: none;

  &:hover {
    color: $button-hover;
    //font-weight: bold;
  }
}
.forgotten-page-header {
  // font-family: "Press Start 2P", cursive;
  color: #648eb3 !important;
  font-size: 60px;
  left: 5%;
  margin-left: 40px;
  filter: blur(1px);
  font-weight: bolder;
  position: absolute;
}
.forgotten-panel-forgotten-pass {
  @include transition(0.7s);
  font-family: "Open Sans", sans-serif;

  background: $panel-bg;
  border-radius: 5px;
  box-shadow: 0 5px 20px $shadow-dark;
  color: $text;
  left: 0;
  width: 600px;
  margin: 0 auto 150px;
  overflow: hidden;
  padding-top: 30px;
  position: relative;
  right: 0;
  top: 50px;
  height: 250px;
  z-index: 200;
  text-align: center;

  button {
    @include transition(0.7s);
    background: $button;
    border: 0;
    border-radius: 6px;
    color: $text;
    cursor: pointer;
    display: block;
    font-size: 1.5em;
    font-weight: 300;
    margin: 40px auto;
    padding: 10px;
    width: 180px;

    &:active {
      background: $button-active;
      color: $text;
    }

    &:hover {
      @include transition(0.55s);
      background: $button-hover;
      box-shadow: 0 2px 8px $shadow-dark;
      color: $panel-bg;
      text-shadow: 0 0 1px $shadow-dark;
    }
  }

  &:hover {
    @include transition(0.7s);
    border-radius: 35px 35px 35px 5px;
    box-shadow: 0 2px 5px $shadow-dark;
  }
}

.forgotten-panel-group {
  width: 50%;
  margin-left: 145px;
  position: relative;
  font-size: 1.5em;
}

.forgotten-input {
  margin-top: 40px;
  border: 0;
  border-bottom: 1px solid $text;
  display: block;
  font-size: 1em;
  padding: 10px 10px 10px 5px;

  &:focus {
    outline: 0;
  }
}

.forgotten-form {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
label {
  @include transition(0.2s);
  color: $placeholder;
  font-size: 14px;
  font-weight: normal;
  left: 5px;
  pointer-events: none;
  position: absolute;
  top: 10px;
}

//animate label when input is in focus
.forgotten-input:focus ~ label,
.forgotten-input:valid ~ label {
  color: $button-hover;
  font-size: 0.75em;
  font-weight: bold;
  top: -20px;
}

//animate bottom border when input is in focus*/
.forgotten-border {
  display: block;
  position: relative;
  width: 315px;
}

.forgotten-border::before,
.forgotten-border::after {
  @include transition(0.5s);
  background: $button-active;
  bottom: 1px;
  content: "";
  height: 2px;
  position: absolute;
  width: 0;
}

.forgotten-border::before {
  left: 50%;
}

.forgottenborder::after {
  right: 50%;
}

//border width when clicked*/
.forgotten-input:focus ~ .border::before,
.forgotten-input:focus ~ .border::after {
  width: 50%;
}

.forgotten-panel-footer {
  font-size: 1.2em;
}

footer {
  bottom: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
}

.forgotten-follow {
  margin: 0 auto;
}

@media only screen and (max-width: 667px) {
  .forgotten-panel {
    width: 400px;
  }
  .forgotten-input {
    margin-top: 20px;
  }
}
