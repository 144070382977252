.orders-page-root-container-parent-parent {
  background: rgba(0, 0, 0, 0.634) url("../../../public/backgorund.jpg")
    no-repeat center center fixed;
  background-blend-mode: multiply;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow: hidden;
  margin: 0;
  padding: 0;
  min-height: 120vh;
  overflow-y: auto;
}
.history-page-container {
  // background: url("../../../public/trade-page-background.jpg");
  // font-family: "Roboto", sans-serif !important;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow: hidden;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  overflow-y: auto;
}

.history-page-subcontainer {
  background: #090c11;
}
.history-page-sub-navbar-container {
  width: 100%;
  height: 50px;
  background: #131722;
  border: 1px solid rgba(255, 255, 255, 0.3);

  margin-top: 5rem;
}

.history-page-sub-navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 60%;
  margin: 0 auto;
  list-style-type: none;
}

li {
  margin-top: 10px;
  text-align: center;
  cursor: pointer;
}
a {
  transition: none;
}

.trading-history-container {
  color: white;
  width: 50%;
  height: 30%;
  margin: 0 auto;
  background-color: #07232e;
  padding-top: 30px;
  padding-bottom: 50px;
  border-radius: 5px;
  position: absolute;
  top: 5%;
  left: 0;
  right: 0;
  border: 1px solid #535353;
}

.trading-history-parent-container {
  width: 100%;
  height: 150px;
  background: #131722;
  position: relative;
}
h4 {
  margin-left: 1rem;
}

.history-span {
  margin-left: 1rem;
  margin-top: 10px;
  background: #0d6372;
  padding: 10px;
  width: 95%;
}

@media only screen and (max-width: 657px) {
  .trading-history-container {
    width: 70%;
  }
  .history-span {
    width: 70%;
  }
}

.orders-page-root-container-parent {
  width: 100%;
  background: #131722;
  position: relative;
  height: 600px;

  .orders-page-root-container {
    width: 70%;
    height: 80%;
    margin: 0 auto;
    position: absolute;
    top: 3%;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: space-between;
    background-color: #07232e;
    padding-top: 15px;
    padding-bottom: 50px;
    border-radius: 5px;
    border: 1px solid rgb(83, 83, 83);
    padding-left: 1rem;
    padding-right: 1rem;

    .orders-page-container-header {
      height: 5%;
      text-align: left;
      color: white;
      font-size: 14;
      border-bottom: 1px solid rgba(226, 232, 245, 0.1);
      padding-bottom: 10px;
      width: 100%;
      margin-bottom: 10px;
    }
  }
}
