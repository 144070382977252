.trade-page-container {
  // background: url("../../../public/trade-page-background.jpg");
  background: rgba(0, 0, 0, 0.634) url("../../../public/backgorund.jpg")
    no-repeat center center fixed;
  background-blend-mode: multiply;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow: hidden;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  overflow-y: auto;
  .trade-page-subcontainer {
    margin-top: 5rem;
  }
}

.trade-page-sub-navbar-container {
  width: 100%;
  height: 50px;
  background: #131722;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-left: none;
  border-right: none;
  margin-top: 5rem;
}

.trade-page-sub-navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 70%;
  margin: 4px auto;
  list-style-type: none;
  @media only screen and (max-width: 1250px) {
    width: 100%;
  }
}

li {
  margin-top: 10px;
  text-align: center;
  cursor: pointer;
}
a {
  transition: none;
}

.trading-widgets-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
  height: 50%;
  margin: 0 auto;
  background-color: #131722;
  padding-top: 10px;
  //padding-bottom: 50px;
  border-radius: 5px;
  //border: 1px solid #535353;
}

.trading-wrap {
  display: flex;
  flex-direction: column;
  margin-right: 1%;
  .widgets-market {
    display: flex;
    border-left: none;
    border-right: 1px solid rgba(102, 102, 102, 0.4);
    border-top: 1px solid rgba(102, 102, 102, 0.4);
    border-bottom: 1px solid rgba(102, 102, 102, 0.4);
  }
}

@media only screen and (max-width: 667px) {
  .trading-wrap {
    width: 100%;
  }
  .widgets-market {
    flex-direction: column;
  }
  .trading-widgets-container {
    padding-top: 0;
    display: flex;
    flex-direction: column;
  }
  .crypto-container {
    width: 98%;
    align-self: center;
    margin-top: 5%;
  }
  .trade-market-container {
    // width: 100%;
    width: 90%;
    padding: 0;
    margin: 10% auto;
    text-align: start;
  }
}

@media only screen and (min-width: 1050px) and (max-width: 1300px) {
  .trade-market-container {
    padding: 0;
  }
  .widgets-market {
    width: 90%;
  }
}
