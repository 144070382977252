// scss-lint:disable VendorPrefix, Indentation
$bg: #616161;
$text-light: #fafafa;
$text: #212121;
$placeholder: #999;
$panel-bg: #0a5f76;
$shadow-dark: rgba(0, 0, 0, 0.65);
$button: #648eb3;
$button-hover: #e2ebea;
$button-active: #18aa7c;

//MIXINS
@mixin transition($time) {
  -webkit-transition: all $time;
  transition: all $time;
}

.login-container {
  margin-top: 150px;
}

.login-h2-header {
  color: black;
  width: 22rem;
  /* height: 5rem; */
  font-size: 1.5rem;
  background: #5485b1;
  margin: 0 auto;
  border-radius: 0.5rem;
}
.login-page-header {
  // font-family: "Press Start 2P", cursive;
  color: #648eb3 !important;
  font-size: 60px;
  left: 5%;
  margin-left: 40px;
  filter: blur(1px);
  font-weight: bolder;
  position: absolute;
}
a {
  @include transition(0.4s);
  color: $button-active;
  text-decoration: none;

  &:hover {
    color: $button-hover;
    //font-weight: bold;
  }
}

.panel {
  @include transition(0.7s);
  // font-family: "Open Sans", sans-serif;

  background: $panel-bg;
  border-radius: 5px;
  box-shadow: 0 5px 20px $shadow-dark;
  color: $text;
  left: 0;
  width: 600px;
  margin: 0 auto 150px;
  overflow: hidden;
  padding-top: 30px;
  position: relative;
  right: 0;
  top: 50px;
  height: 26rem;
  // height: 400px;
  z-index: 200;
  text-align: center;

  button {
    @include transition(0.7s);
    background: $button;
    border: 0;
    border-radius: 6px;
    color: $text;
    cursor: pointer;
    display: block;
    font-size: 1em;
    font-weight: 300;
    margin: 20px auto;
    padding: 10px;
    width: 20rem;

    &:active {
      background: $button-active;
      color: $text;
    }

    &:hover {
      @include transition(0.55s);
      background: $button-hover;
      box-shadow: 0 2px 8px $shadow-dark;
      color: $panel-bg;
      text-shadow: 0 0 1px $shadow-dark;
    }
  }

  &:hover {
    @include transition(0.7s);
    border-radius: 35px 35px 35px 5px;
    box-shadow: 0 2px 5px $shadow-dark;
  }
}

.panel-group {
  margin: 20px auto;
  position: relative;
  width: 70%;
  font-size: 1.5em;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.input {
  border: 0;
  border-bottom: 1px solid $text;
  display: block;
  font-size: 1em;
  padding: 10px 10px 10px 5px;
  &:focus {
    outline: 0;
  }
}

label {
  @include transition(0.2s);
  color: $placeholder;
  font-size: 14px;
  font-weight: normal;
  left: 5px;
  pointer-events: none;
  position: absolute;
  top: 10px;
}

//animate label when input is in focus
.input:focus ~ label,
.input:valid ~ label {
  color: $button-hover;
  font-size: 0.75em;
  font-weight: bold;
  top: -22px;
}

//animate bottom border when input is in focus*/
.border {
  display: block;
  position: relative;
  width: 315px;
}

.border::before,
.border::after {
  @include transition(0.5s);
  background: $button-active;
  bottom: 1px;
  content: "";
  height: 2px;
  position: absolute;
  width: 0;
}

.border::before {
  left: 50%;
}

.border::after {
  right: 50%;
}

//border width when clicked*/
.input:focus ~ .border::before,
.input:focus ~ .border::after {
  width: 48%;
}

.panel-footer-login {
  font-size: 1.2em;
}

footer {
  bottom: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
}

.follow {
  margin: 0 auto;
}

.form {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 667px) {
  .login-container {
    margin-top: 100px;
  }

  .login-page-header {
    // font-family: "Press Start 2P", cursive;
    color: #648eb3 !important;
    font-size: 30px;
    filter: blur(1px);
    font-weight: bolder;
  }

  .panel {
    // margin:0 auto;
    // padding-top: 40px;
    background: $panel-bg;
    border-radius: 2px;
    box-shadow: 0 5px 20px $shadow-dark;
    color: $text;
    // left: 0;
    padding: 10px;
    width: 90%;
    // margin: 0 auto 150px;
    text-align: center;

    // margin-bottom: 20px;
    // height: 350px;

    button {
      @include transition(0.7s);
      background: $button;
      border: 0;
      border-radius: 2px;
      color: $text;
      cursor: pointer;
      display: block;
      font-size: 16px;
      font-weight: 300;
      margin: 20px auto;
      padding: 10px;
      width: 60%;
    }

    &:hover {
      @include transition(0.7s);
      border-radius: 35px 35px 35px 5px;
      box-shadow: 0 2px 5px $shadow-dark;
    }
  }
  .panel-group {
    margin-bottom: 2rem;
    font-size: 1.5em;
    // margin-left: 1rem;
    height: 20%;
  }
  .form {
    margin-top: 0;
  }
  // .input {
  //   border-bottom: 1px solid $text;
  //   font-size: 1em;
  //   margin: 0 auto;

  //   &:focus {
  //     border-bottom: none;
  //   }
  // }

  label {
    font-size: 14px;
  }

  a {
    font-size: 16px;
  }
}
