.navbar {
  box-shadow: none !important;
}

.header-image-container {
  color: white;
  font-weight: bold;
  font-size: 35px;
  cursor: pointer;
  position: fixed;
  right: 1rem;
  width: 250px;
  height: 220px;
  top: -8%;
  left: -3%;
  cursor: pointer;
}
@media only screen and (max-width: 667px) {
  .header-image-container {
    img {
      height: 120px;
      width: 200px;
    }
  }
  .header-image-container {
    color: white;
    font-weight: bold;
    font-size: 35px;
    cursor: pointer;
    position: fixed;
    right: 1rem;
    width: 100px;
    height: 160px;
    top: -3%;
    left: -2%;
  }
}
.navbar-on-scroll {
  background: linear-gradient(360deg, #353535, #000000);
  box-shadow: 0px 5px 15px 2px rgba(0, 0, 0, 0.7);
  display: none;
}
.header-logo {
  display: flex;
  justify-content: center;
}
