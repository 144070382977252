.faq-page-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.faq-page-header {
  // font-family: "Press Start 2P", cursive;
  display: flex;
  margin-top: 50px;
  margin-left: 8%;
  font-size: 60px;
  font-weight: bolder;
  color: #648eb3 !important;
  filter: blur(1px);
}

.faq-page-textBox {
  // font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 35px;
  margin-top: 50px;
  position: relative;
  text-align: center;
}
.faq-page-h3 {
  color: white;
  text-shadow: 1px 1px 4px black;
}

.faq-page-span {
  width: 50%;
  margin: 10px auto;
  font-size: 24px;
  color: #cdd6f6;
  // font-family: "Open Sans", sans-serif;
  text-shadow: 1px 1px 4px black;
}

.faq-page-imagebox {
  margin-right: 100px;
  z-index: 99;
  display: none;
}

.faq-page-imagebox2 {
  position: absolute;
  right: 0;
  top: -9%;
  z-index: -1;
  height: 100px;
}

.faq-types-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
}

.faq-types-headers {
  // font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  text-shadow: 1px 1px 4px black;

  color: white;
}

.faq-types-spans {
  font-size: 18px;
  // font-family: "Open Sans", sans-serif;
  color: #cdd6f6;
  font-weight: bold;
  text-shadow: 1px 1px 3px black;
}

.faq-accordion {
  width: 600px;
  margin: 20px 0px;
}

.faq-types-container-main {
  margin-top: 50px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
}

.faq-typography-header {
  font-size: 20px !important;
  // font-family: "Open Sans", sans-serif !important;
  color: #648eb3;
  font-weight: bold !important;
}

.faq-typography {
  font-size: 15px !important;
  // font-family: "Open Sans", sans-serif !important;
  color: black;
  font-weight: bold !important;
  text-shadow: 1px 1px 3px white;
}

@media only screen and (max-width: 667px) {
  .faq-page-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    // margin-top: 50px;
    align-items: center;
    width: 100%;
    text-align: center;
  }
  .faq-types-container-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    text-align: center;
  }
  .faq-accordion {
    width: 350px;
  }
  .faq-page-h3 {
    font-size: 30px;
  }
  .faq-types-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    // margin-top: 100px;
  }
  .faq-page-header {
    font-size: 2.5rem;
  }

  .faq-types-headers {
    font-size: 30px;
    margin-bottom: 20px;
    margin-top: 30px;
  }

  .faq-page-span {
    width: 90%;
    margin-top: 20px;
    font-size: 20px;
  }

  .faq-text-container {
    margin: 0 auto;
  }
}
