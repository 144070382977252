.account-documents-container-parent-root {
  background: rgba(0, 0, 0, 0.634) url("../../../public/backgorund.jpg")
    no-repeat center center fixed;
  background-blend-mode: multiply;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow: hidden;
  margin: 0;
  padding: 0;
  min-height: 120vh;
  overflow-y: auto;
}
.account-documents-container-parent {
  background: #131722;
  // min-height: 500px;
  padding: 20px 0;
  // height: 100%;
  // position: relative;
  @media only screen and (max-width: 667px) {
    height: 140vh;
  }
  .account-documents-button {
    margin: 12px;
  }
}
.account-documents-container {
  background: #131722;
  margin: 0 auto;
  font-size: 12px;
  color: white;
  width: 89%;
  min-height: 400px;
  background-color: #07232e;
  padding-top: 10px;
  border-radius: 5px;
  border: 1px solid #535353;
  @media only screen and (max-width: 667px) {
    height: 120vh;
  }
}

.account-documents-header {
  margin-left: 4rem;
  padding-top: 20px;
  text-align: left;
  @media only screen and (max-width: 667px) {
    display: flex;
    justify-content: center;
    margin: 0;
  }
}

.account-documents-boxes {
  display: flex;
  justify-content: space-between;
  // align-items: center;
  margin-top: 30px;
  .account-documents-box {
    background-color: rgb(1, 104, 118);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin: 6px;
    border-radius: 5px;
    padding: 12px;
    .account-documents-box-header {
      font-size: 28px;
      border-bottom: 2px solid white;
    }
    .account-documents-box-text {
      margin-top: 8px;
      font-size: 18px;
    }
    @media only screen and (max-width: 667px) {
      width: auto;
    }
  }
  @media only screen and (max-width: 667px) {
    flex-direction: column;
  }
}

.account-documents-box-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

// .account-documents-cards {
// 	margin-top: 30px;
// 	display: flex;
// 	justify-content: space-evenly;
// 	flex-direction: row;
// @media only screen and (max-width: 658px) {
// 	flex-direction: column;
// }
// }

// .account-documents-card-actions {
// 	display: flex;
// 	flex-direction: column;
// 	justify-content: center;
// 	background: #0d6372;
// 	border-top: 1px solid white;
// 	background-color: #039e93;
// }

// @media only screen and (max-width: 658px) {
// 	.account-types-header {
// 		margin: 0 auto;
// 	}

// 	.account-types-p {
// 		font-size: 0.8rem;
// 	}

// 	.account-docs-header {
// 		font-size: 1rem;
// 	}

// 	.typography-styles {
// 		font-size: 0.1rem;
// 	}
// }
